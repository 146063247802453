.tabs {
      width: 100%;
      display: flex;
      gap: 20px;
      border-bottom: 1px solid rgba(0,0,0,.05);

      @media screen and (max-width:500px) {
            
      }
}

.item {
      display: flex;
      align-items: center;
      gap:10px;

      margin-bottom: -1px;
      padding-bottom: 15px;
      border-bottom: 3px solid rgba(0,0,0,0);
      text-decoration: none;
      color: black;
      font-size: 18px;

      &_active {
            border-bottom: 3px solid var(--primary500);
      }
}