.filters {
    position: sticky;
    top: 0;

    padding: 10px 0;
    background: #fff;

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap:20px;


    @media screen and (max-width:500px) {
        grid-template-columns: 100%;
        gap: 10px;

        position: relative;
    }
}

.ValueContainer {
    flex-wrap: nowrap;
}