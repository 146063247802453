.countrySelect {
    margin-top: 40px;

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap:20px;

    @media screen and (max-width:500px) {
        grid-template-columns: 100%;
        gap: 40px;
  }

    &__item {
        text-decoration: none;
    }

    &__img {
        margin-bottom: 20px;

        width: 100%;
        height: 350px;

        border-radius: 20px;
        background: rgba(0,0,0,.1);
    }

    &__link {
        display: flex;
        gap: 10px;
        align-items: center;
        text-decoration: none;

        font-size: 18px;
        color: var(--blue500);
    }
}