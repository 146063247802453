html {
    --primaryDark: rgb(44, 72, 174);
    --primary500: rgb(71, 107, 240);
    --blue500: rgb(71, 107, 240);
    --gray800: #7F858D;
}

.heading {
    &_XL {
        font-size: 48px;
        font-weight: bold;
    }

    &_L {
        font-size: 40px;
        font-weight: bold;
    }

    &_M {
        font-size: 32px;
        font-weight: bold;
    }

    &_S {
        font-size: 24px;
        font-weight: bold;
    }  
}

.link {
    color: var(--blue500);
    text-decoration: none;
}

.text {
    &_L {
        font-size: 18px;
    }

    &_M {
        font-size: 16px;
    }

    &_S {
        font-size: 14px;
    }
}

.tag {
    &_M {
        margin-bottom: 3px;

        color: var(--primary500);
        font-size: 16px;
        letter-spacing: 1px;
        text-transform: uppercase;
    }

    &_S {
        margin-bottom: 3px;

        color: var(--primary500);
        font-size: 12px;
        letter-spacing: 1px;
        text-transform: uppercase;
    }
}