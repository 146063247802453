.citySelect {
      margin-top: 80px;
      &__list {
            margin-top: 30px;

            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap:20px;
            
            @media screen and (max-width:500px) {
                  grid-template-columns: 100%;
                  gap: 40px;
            }
      }

      &__item {
            display: flex;
            flex-direction: column;
            border-radius: 20px;
      }

      &Item {
            &__img {
                  height: 200px;
                  width: 100%;
                  border-radius: 20px 20px 0 0;
            }
      
            &__description {
                  display: flex;
                  flex-direction: column;
                  gap: 5px;
                  padding: 20px;
                  border: 1px solid rgba(0,0,0,.05);
                  border-radius: 0 0 20px 20px;
            }

            &__name {
                  font-weight: bold;
            }
      }

      
}