
.heroScreen {
      position: relative;
      margin-bottom: 100px;

      padding: 50px;
      padding-top: 20px;
      box-sizing: border-box;

      height: 650px;
      width: 100%;

      background: radial-gradient(97.29% 268.92% at 2.71% 4.48%, #E3F2FF 0%, #F7F2FF 63.01%, #FFEEEE 90.73%);

      @media screen and (max-width:500px) {
            margin-bottom: 0px;
            padding: 20px;
            height: auto;
            background: none;
      }

      &Img {
            position: absolute;
            right: 0;
            bottom: 0;
            width: 650px;
            height: 100%;

            background: url('../img/heroScreenBg.png');
            background-size: cover;

            @media screen and (max-width:500px) {
                  display: none;
            }
      }

      &Img_Turkey {
            position: absolute;
            right: 0;
            bottom: 0;
            width: 750px;
            height: 100%;

            background: url('../img/heroScreenBgTurkey.png');
            background-size: cover;

            @media screen and (max-width:500px) {
                  display: none;
            }
      }

      &Badge {
            position: absolute;
            right: 60px;
            top: 20px;
            width: 250px;
            height: 250px;

            background: url('../img/heroScreenBadge.png');
            background-size: cover;
      }

      &__contentContainer {
            @media screen and (max-width:500px) {
                  margin-bottom: 20px;
                  display: none;
            }
      }

      
}

.pageHeading {
      &__firstLine{
            margin-top: 80px;
            position: relative;
            padding: 15px 20px;
            display: inline-block;

            background: var(--primaryDark);

            font-size: 48px;
            font-weight: bold;
            color: #fff;
      }

      &__secondLine {
            @extend .pageHeading__firstLine; 
            
            padding-top: 0px;
            margin-top: -30px;
      }
}

.subHeading {
      margin-top: 30px;
      width: 550px;

      font-size: 20px;
      color: rgba(0,0,0,.6);
      

      &__p {
            margin-bottom: 10px;
      }
}

.logo {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;

      svg {
            @media screen and (max-width:500px) {
                  width: 100%;
            }
      }
}

.page {
      &Header {
            margin-bottom: 20px;

            display: flex;
            justify-content: space-between;
            align-items: center;

            @media screen and (max-width:500px) {
                  flex-direction: column;
                  align-items: normal;
                  gap: 10px;
            }
      }

      
}

.hero__button {
      margin-top: 40px;
}

.mainContainer {
      padding: 30px;
      padding-bottom: 80px;

      @media screen and (max-width:500px) {
            padding: 15px;
      }
}

.hero {
      &Steps {

            padding: 0 30px;

            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap:40px;
      
            @media screen and (max-width:500px) {
                  grid-template-columns: 100%;
            }

            &__container {
                  position: absolute;
                  bottom: -50px;
                  left: 0;
            }

            &__item {
                  position: relative;

                  padding: 15px 50px 15px 30px;
                  background: #FFFFFF;
                  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
                  border-radius: 20px; 
            }
      }

      &Step {
            &__number {
                  position: absolute;
                  left: -15px;
                  top: -15px;

                  height: 40px;
                  width: 40px;

                  background: var(--primary500);
                  border-radius: 20px;

                  font-size: 18px;
                  font-weight: bold;
                  line-height: 40px;
                  text-align: center;
                  color: #fff;
            }

            &__text {
                  font-size: 20px;
                  color: var(--gray800);
            }
      }

}


.footer {
      margin-top: 50px;
      display: flex;
      gap: 20px;

      @media screen and (max-width:500px) {
                  flex-direction: column;
      }

      &Link {
            color: var(--blue500);
            text-decoration: none;
            cursor: pointer; 
      }
}