.container {
      display: none;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow-y: scroll;

      background: rgba(0,0,0,.8);

      &_show {
            display: block;
      }
}

.window {
      margin-left: -425px;
      margin-top: 100px;
      display: flex;

      position: absolute;
      width: 850px;
      left: 50%;
      // padding: 30px;
      // padding-bottom: 40px;
      box-sizing: border-box;

      background: #fff;
      border-radius: 20px;

      @media screen and (max-width:500px) {
            left: 0;
            margin-left: 0;
            margin-top: 0;

            width: 100%;
            flex-direction: column;
      }
}

.heading {
      margin-top: 10px;
      margin-bottom: 10px;

      font-size: 32px;
      font-weight: bold;
      text-align: center;
}

.subHeading {
      margin-bottom: 20px;

      font-size: 18px;
      text-align: center;
      color: var(--gray800);
}

.form {
      display: flex;
      flex-direction: column;

      &_isSent {
            padding-top: 30px;
            height: 100%;

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
      }

}

.input {
      composes: input from "../../library/UI.scss";

      margin-bottom: 15px;
}

.input_white {
      composes: input from "../../library/UI.scss";

      background: #fff;

      margin-bottom: 15px;

}

.input_error {
      composes: input from "../../library/UI.scss";

      background: #FFDEDE;
      box-sizing: border-box;

      border: 2px solid red;

      margin-bottom: 15px;
      padding-left: 18px;

}

.button {
      composes: button from "../../library/UI.scss";
}

.closeIcon {
      position: absolute;
      right: 15px;
      top: 15px;
}

.aboutBlock {
      width: 100%;
      display: flex;
      flex-direction: column;
}

.descriptionBlock {
      padding: 20px;
}

.description {
     &__line {
      display: flex;
     }
     &__item {
     
     }
}

.text {
      margin-top: 15px;
      font-size: 18px;
}

.photo {
      height: 300px;
      width: 100%;

      border-radius: 20px 0px 0 0px;

      @media screen and (max-width:500px) {
            border-radius: 20px 20px 0 0;
      }
}

.name {
      composes: heading_M from "../../library/library.scss";
}

.price {
      font-size: 20px;
}



.form__heading {
      composes: heading_S from "../../library/library.scss";

      &_center {
            text-align: center;
      }
}

.form__subheading {
      margin-top: 5px;
      margin-bottom: 15px;

      composes: text_M from "../../library/library.scss";
      color: var(--gray800);

      font-size: 18px;

      &_center {
            text-align: center;
      }
      
}

.formSuccessImg {
      margin-bottom: 20px;
      width: 100px;
      height: 100px;
      background: url('../img/approved.png');
      background-size: cover;
}

.company {
      composes: tag_M from "../../library/library.scss";
}

.additionalInfo {
      margin-top: 5px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      color: var(--gray800);

      & div {
            margin-right: 5px;

            &:last-child {
                  margin-right: 0;
            }
      }
}

.formBlock {
      padding: 30px;
      min-width: 400px;
      width: 400px;

      box-sizing: border-box;

      border-radius: 0 20px 20px 0;
      background: #F0F0F0;

      @media screen and (max-width:500px) {
            min-width: auto;
            width: 100%;
            border-radius: 0 0 20px 20px;
      }
}

.verticalForm {
      display: flex;
      flex-direction: column;

}

.lineForm {
      display: flex;

      & input {
            flex-grow: 1;
            border-radius: 0;
            border-left: 1px solid rgba(0,0,0,.05);
      }

      & input:first-child{
            border: none;
            border-radius: 10px 0px 0px 10px;
      }

      & input:last-child{
            border: none;
            border-radius: 0px 10px 10px 0px;
      }

      & input[type=submit] {
            flex-grow: initial;
            padding: 0 20px;
      }
}


.checkboxContainer {
      display: block;
      position: relative;
      padding-left: 35px;
      margin-bottom: 20px;
      cursor: pointer;
      font-size: 18px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      &:hover input ~ .checkmark {
            border: 2px solid var(--primary500);
      }

      & input:checked ~ .checkmark {
            background-color: var(--primary500);
      }

      & input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;

            &:checked ~ .checkmark:after {
                  display: block;
            }
      }

      & .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 20px;
            width: 20px;
            border-radius: 4px;
            border: 2px solid var(--primary500);

            &:after {
                  content: "";
                  position: absolute;
                  display: none;

                  left: 6px;
                  top: 2px;
                  width: 5px;
                  height: 10px;
                  border: solid white;
                  border-width: 0 3px 3px 0;
                  -webkit-transform: rotate(45deg);
                  -ms-transform: rotate(45deg);
                  transform: rotate(45deg);
            }
      }

      &_error .checkmark{
            border: 2px solid red;
      }
}

.link {
      composes: link from "../../library/library.scss";
}

.mortgage {
      border-radius: 5px;
      padding: 10px 15px;
      background: #e4eaff;
      color: var(--primary500);
      font-weight: bold;
      font-size: 18px;

      &Percentage {
            display: inline-block;
            color: var(--primary500);
      }
}