.container {
      width: 400px;
      height: 100%;
      position: fixed;
      right: 0;
      top: 0;

      @media screen and (max-width:1200px) {
            display: none;
        }
}