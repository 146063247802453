.mobileMenu {
  display: none;

  @media screen and (max-width:500px) {
    display: block;
  }
}
.container {
  left: 0;
  top: 0;
  position: fixed;
  padding: 0 15px;
  box-sizing: border-box;

  width: 100%;
  height: 100%;

  background: #31323C;
  z-index: 15;

  font-size: 16px;
  color: rgba(255, 255, 255, 0.8);

  transform: translateX(-500px);
  transition: transform 0.5s;

  &_show {
    transform: translateX(0px);
  }

  @media screen and (max-width:500px) {
    grid-template-columns: 100%;
  }
}

.header {
  padding: 15px;
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  border-bottom: 1px solid #E6EAF2;
}

.headerWrapper {
  width: 24px;
}

.mobileBlock {
  border-bottom: 1px solid #5A5D62;
  padding-bottom: 20px;
  padding-top: 20px;

  &:first-child
  {
      margin-top: 40px;
  }

  &:last-child {
    border-bottom: 0;
  }
}

.closeIcon {
  margin-top: 15px;
}

.mobileItem {
  margin-bottom: 20px;

  display: flex;
  align-items: center;
  text-decoration: none;

  &:last-child {
    margin-bottom: 0;
  }

  &__img {
      margin-right: 15px;
      width: 24px;
      height: 24px;
  }

  &__name {
      color: rgba(255, 255, 255, 0.8); 
      text-decoration: none;
      
      &_active {
          color: #AB90FF;
      }
  }


}